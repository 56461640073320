<template>
  <div class="home">
    <div class="cols links">
      <div class="sources">
        <span>Nous écouter partout :</span><br/>
        <a class="btn external" download="radiobrony.m3u"
           href="/radiobrony.m3u">
          <MusicCircleIcon decorative title=""/>&thinsp;
          Lecteur externe
        </a>
        <a class="btn popup" @click="openPopup">
          <WindowRestoreIcon decorative title=""/>&thinsp;
          Popup
        </a>
        <a v-if="pipDocSupport && !pipWindow" class="btn popup" @click="openPip">
          <PictureInPictureBottomRightIcon decorative title=""/>&thinsp;
          PiP
        </a>
        <a v-else-if="pipDocSupport && pipWindow" class="btn popup" @click="closePip">
          <PictureInPictureBottomRightOutlineIcon decorative title=""/>&thinsp;
          PiP
        </a>
        <br/>
        <a class="btn browsers"
           target="_blank" rel="noopener noreferrer"
           href="https://hoofsounds.little.my/">
          <GoogleChromeIcon class="simple-icons" size="1rem"/>&thinsp;
          <FirefoxBrowserIcon class="simple-icons" size="1rem"/>&thinsp;
          Extension
        </a>
        <a class="btn tunein"
           target="_blank" rel="noopener noreferrer"
           href="https://tunein.com/radio/Radio-Brony-s185719/">
          <TuneInIcon class="simple-icons" size="1rem"/>&thinsp;
          TuneIn
        </a>
      </div>
      <div class="social">
        <span>Nous suivre et nous rejoindre :</span><br/>
        <a class="btn mastodon"
           target="_blank" rel="me noopener noreferrer"
           href="https://equestria.social/@RadioBrony">
          <MastodonIcon class="simple-icons" size="1rem"/>&thinsp;
          Mastodon</a>
        <a class="btn discord"
           target="_blank" rel="noopener noreferrer"
           href="http://discord.gg/MdhuDym">
          <DiscordIcon class="simple-icons" size="1rem"/>&thinsp;
          Discord</a>
        <br/>
        <a class="btn lastfm"
           target="_blank" rel="me noopener noreferrer"
           href="https://www.last.fm/user/RadioBrony">
          <LastDotfmIcon class="simple-icons" size="1rem"/>&thinsp;
          Last.fm</a>
        <a class="btn twitch"
           target="_blank" rel="me noopener noreferrer"
           href="https://twitch.tv/radiobronyfr">
          <TwitchIcon class="simple-icons" size="1rem"/>&thinsp;
          Twitch</a>
        <a class="btn youtube"
           target="_blank" rel="me noopener noreferrer"
           href="https://www.youtube.com/@RadioBrony">
          <YouTubeIcon class="simple-icons" size="1rem"/>&thinsp;
          YouTube</a>
      </div>
    </div>

    <!--
    <h3><StarCircleIcon decorative title=""/> Événement</h3>
    <GDQ :event-name="'SGDQ2024'" :charity-name="'Médecins Sans Frontières'" />
    -->

    <h3><MicrophoneIcon decorative title=""/> Dernier podcast</h3>
    <div v-if="lastEps" class="latest">
      <NuxtLink
        class="route-link"
        :to="`/podcasts/show/${lastEps.latest[0].show.slug}/episode/${lastEps.latest[0].episode.season}/${lastEps.latest[0].episode.episode}`">
        <div class="episode">
          <img
            class="cover"
            loading="lazy"
            v-trans="`ep-${lastEps.latest[0].episode.id}`"
            :alt="lastEps.latest[0].episode.title"
            :srcset="`${lastEps.latest[0].episode.cover['90']}, ${lastEps.latest[0].episode.cover['180']} 2x, ${lastEps.latest[0].episode.cover['270']} 3x`"
            :src="lastEps.latest[0].episode.cover['270']"/>
          <div class="text">
            <h4>{{ lastEps.latest[0].episode.title }}</h4>
            <span v-if="lastEps.latest[0].episode.author">Présenté par {{ lastEps.latest[0].episode.author }}</span>
            <blockquote v-if="lastEps.latest[0].episode.subtitle" v-html="lastEps.latest[0].episode.subtitle"></blockquote>
            <span class="details">
              <span>Saison {{ lastEps.latest[0].episode.season }} - Episode {{ lastEps.latest[0].episode.episode }}</span>
              <span v-if="lastEps.latest[0].episode.duration"> - Durée : {{ parseDuration(lastEps.latest[0].episode.duration) }}</span><br/>
              <span v-if="lastEps.latest[0].episode.recDate">Enregistré le {{ parseDateTime(lastEps.latest[0].episode.recDate) }}<br/></span>
              <span>Paru le {{ parseDateTime(lastEps.latest[0].episode.pubDate) }}</span>
            </span>
          </div>
        </div>
      </NuxtLink>
    </div>

    <h3><MastodonIcon class="simple-icons" size="1rem"/> Posts</h3>
    <home-timeline/>

    <h3><CalendarIcon decorative title=""/> Prochaines émissions</h3>
    <home-events/>

    <h3><StarCircleIcon decorative title=""/> Recommendations</h3>
    <home-reco/>
  </div>
</template>

<script setup>
// Vue
import { ref, onMounted } from 'vue'
// Deps
import { DateTime, Duration } from 'luxon'
// Icons
import {
  DiscordIcon,
  FirefoxBrowserIcon,
  GoogleChromeIcon,
  LastDotfmIcon,
  MastodonIcon,
  TuneInIcon,
  TwitchIcon,
  YouTubeIcon
} from '@mkody/vue3-simple-icons'
import CalendarIcon from '@mkody/vue-material-design-icons/Calendar.vue'
import MicrophoneIcon from '@mkody/vue-material-design-icons/Microphone.vue'
import MusicCircleIcon from '@mkody/vue-material-design-icons/MusicCircle.vue'
import PictureInPictureBottomRightIcon from '@mkody/vue-material-design-icons/PictureInPictureBottomRight.vue'
import PictureInPictureBottomRightOutlineIcon from '@mkody/vue-material-design-icons/PictureInPictureBottomRightOutline.vue'
import StarCircleIcon from '@mkody/vue-material-design-icons/StarCircle.vue'
import WindowRestoreIcon from '@mkody/vue-material-design-icons/WindowRestore.vue'

const pipDocSupport = ref(false)
const pipWindow = ref(null)

const openPopup = () => {
  window.open(
    'https://embed.radiobrony.fr',
    'PlayerRB',
    'menubar=no, scrollbars=no, width=720, height=200'
  )
}

const openPip = async () => {
  // Open the player in a PiP for browsers that supports it
  const player = document.querySelector('div.radio')

  // Create some additional elements to make the styles fit
  const pipApp = document.createElement('div')
  pipApp.setAttribute('id', 'app')
  pipApp.className = document.querySelector('#app').className

  // Open a Picture-in-Picture window
  pipWindow.value = await documentPictureInPicture.requestWindow({
    height: 350,
    width: 450
  })

  // Copy style sheets over from the initial document
  // so that the player looks the same
  ;[...document.styleSheets].reverse().forEach((styleSheet) => {
    try {
      const cssRules = [...styleSheet.cssRules].map((rule) => rule.cssText).join('')
      const style = document.createElement('style')

      style.textContent = cssRules
      pipWindow.value.document.head.appendChild(style)
    } catch (e) {
      const link = document.createElement('link')

      link.rel = 'stylesheet'
      link.type = styleSheet.type
      link.media = styleSheet.media
      link.href = styleSheet.href
      pipWindow.value.document.head.appendChild(link)
    }
  });

  // Add styles to adapt
  const pipStyles = document.createElement('style')
  pipStyles.textContent = `
    body {
      overflow: hidden;
      padding: 0;
    }
    #app {
      height: 100%;
      max-width: 100%;
      padding: 0;
    }
    .radio {
      background: transparent !important;
      height: 100%;
      margin: 0 !important;
    }
    /* Bugs? */
    .liverb-player .ctrl {
      padding-bottom: 5px !important;
    }
    .liverb-player .vol {
      margin-top: 12px !important;
    }
  `
  pipWindow.value.document.head.appendChild(pipStyles)

  // Move the player to the Picture-in-Picture window
  pipApp.append(player)
  pipWindow.value.document.body.append(pipApp)

  // Move the player back when the Picture-in-Picture window closes
  pipWindow.value.addEventListener('pagehide', (event) => {
    const playerContainer = document.querySelector('header.main-header')
    const pipPlayer = event.target.querySelector('div.radio')
    playerContainer.append(pipPlayer)
    pipWindow.value = null
  })
}

const closePip = () => {
  if (pipWindow) pipWindow.value.close()
  else pipWindow.value = null
}

const parseDateTime = (str) => {
  const dt = DateTime.fromSQL(str, { zone: 'Europe/Paris' })
  return dt.setLocale('fr').toLocaleString(DateTime.DATETIME_MED)
}

const parseDuration = (sec) => {
  return Duration.fromObject({ seconds: sec }).toFormat('hh:mm:ss')
}

const { data: lastEps } = await useFetch('https://pod.radiobrony.fr/api/latest')

useHead({
  script: [
    {
      type: 'application/ld+json',
      children: JSON.stringify({
        '@context': 'https://schema.org',
        '@type': 'Organization',
        'name': 'Radio Brony',
        'url': 'https://radiobrony.fr',
        'logo': 'https://radiobrony.fr/img/icons/bwavatar-details.png'
      })
    }
  ]
})

onMounted(() => {
  if (process.client && 'documentPictureInPicture' in window) {
    // Enable PiP button
    pipDocSupport.value = true
    // Grab current opened PiP, if any
    pipWindow.value = documentPictureInPicture.window
  }
})
</script>

<style lang="scss">
.home {
  h3 {
    margin: 30px 0 10px;
  }

  .btn {
    margin: 3px 2px 0 0;
  }

  .latest {
    background-color: #ffffff;

    .episode {
      border: 1px solid #808080;
      border-radius: 5px;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
      cursor: pointer;
      display: flex;
      margin: 10px 0;
      overflow: hidden;
      transition: all 0.3s cubic-bezier(.25,.8,.25,1);

      &:hover {
        box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
      }

      .cover {
        height: 90px;
        min-height: 90px;
        min-width: 90px;
        width: 90px;
      }

      .text {
        padding: 15px;

        h4 {
          margin: 0;
        }

        blockquote {
          margin: 10px 0;
          border-left: 3px gray solid;
          padding: 5px 10px;
        }
      }
    }
  }

  .links {
    margin-top: 20px;

    .social,
    .sources {
      flex: 1;
      padding: 5px;

      & > span {
        display: inline-block;
        margin-bottom: 5px;
      }
    }
  }

  .cols {
    align-items: flex-start;
    display: flex;
  }

  @media (max-width: 719px) {
    .links {
      flex-direction: column;
    }
  }
}
</style>
