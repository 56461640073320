<template>
  <div class="home-reco">
    <p>
      On vous recommande ces podcasts, hors de notre station.
    </p>
    <div
      v-for="p in pods"
      :key="p.title"
      class="reco-fiche">
      <div class="reco-image">
        <img
          loading="lazy"
          :srcset="`${p.img_sizes.small}, ${p.img_sizes.medium} 2x, ${p.img_sizes.large} 3x`"
          :src="p.img_sizes.large">
      </div>
      <div class="reco-details">
        <b>{{ p.title }}</b>
        <div class="reco-descript">{{ p.descript }}</div>
        <div class="reco-links">
          <a
            v-for="l in p.links"
            :key="l.link"
            :class="[ 'btn', l.style ]"
            target="_blank"
            rel="noopener noreferrer"
            :href="l.link">
            {{ l.title }}</a>&nbsp;
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'

const pods = ref([
  {
    title: 'Raconte-moi l’espace',
    descript: 'Si tu te poses plein de questions en regardant le ciel, si tu te demandes à quoi servent les satellites, ou comment fonctionnent les fusées… Le Centre national d’études spatiales, le CNES, te donne rendez-vous toutes les semaines pour répondre à tes questions dans "Raconte-moi l’espace", une série de podcast qui répond aux questions scientifiques et spatiales des enfants... petits et grands !',
    img: 'https://podcast.cnes.fr/assets/uploads/2023/08/podcast-raconte-moi-lespace.png',
    img_sizes: {
      small: 'https://t.radiobrony.fr/b9CNgIEmV6_CMv0uUH1YRj4SaEg=/150x0/https://podcast.cnes.fr/assets/uploads/2023/08/podcast-raconte-moi-lespace.png',
      medium: 'https://t.radiobrony.fr/m8pdjQJo9O1UMoisAiKFgr2rFJc=/300x0/https://podcast.cnes.fr/assets/uploads/2023/08/podcast-raconte-moi-lespace.png',
      large: 'https://t.radiobrony.fr/Hd_BztfME4oQodYB0l0WAs6yw9s=/450x0/https://podcast.cnes.fr/assets/uploads/2023/08/podcast-raconte-moi-lespace.png'
    },
    links: [
      {
        title: 'Site',
        link: 'https://podcast.cnes.fr/raconte-moi-lespace/',
        style: ''
      },
      {
        title: 'RSS',
        link: 'https://podcast.cnes.fr/programme/raconte-moi-lespace',
        style: 'external'
      },
      {
        title: 'YouTube',
        link: 'https://www.youtube.com/@CnesFrance',
        style: 'youtube'
      },
      {
        title: '𝕏 (Twitter)',
        link: 'https://x.com/CNES',
        style: 'twitter-x'
      },
      {
        title: 'Facebook',
        link: 'https://www.facebook.com/CNESFrance',
        style: 'facebook'
      }
    ]
  },
  {
    title: 'Origami',
    descript: 'ORIGAMI, c’est un média indépendant et gratuit fondé par des vétérans de la presse spécialisée (Gamekult, JV, Nolife) pour parler - essentiellement mais pas uniquement - de jeu vidéo.',
    img: 'https://ugc.production.linktr.ee/8c999e5c-596e-41cc-ac34-8a7bdcc6a936_IMG-0066.jpeg',
    img_sizes: {
      small: 'https://t.radiobrony.fr/EmVOcjDHwCtpjnftLd_Xs0-xHy4=/150x0/https://ugc.production.linktr.ee/8c999e5c-596e-41cc-ac34-8a7bdcc6a936_IMG-0066.jpeg',
      medium: 'https://t.radiobrony.fr/XAEDYRjzAGRSDGAtE5IeO6AVCmM=/300x0/https://ugc.production.linktr.ee/8c999e5c-596e-41cc-ac34-8a7bdcc6a936_IMG-0066.jpeg',
      large: 'https://t.radiobrony.fr/XPhv8Si2FkwscV88EeLCkVWUbDc=/450x0/https://ugc.production.linktr.ee/8c999e5c-596e-41cc-ac34-8a7bdcc6a936_IMG-0066.jpeg'
    },
    links: [
      {
        title: 'Twitch',
        link: 'https://www.twitch.tv/origatwitch',
        style: 'twitch'
      },
      {
        title: 'YouTube',
        link: 'https://www.youtube.com/@OrigaTube',
        style: 'youtube'
      },
      {
        title: 'Bluesky',
        link: 'https://bsky.app/profile/origasky.bsky.social',
        style: 'bluesky'
      },
      {
        title: '𝕏 (Twitter)',
        link: 'https://x.com/OrigaTwi',
        style: 'twitter-x'
      }
    ]
  },
  {
    title: 'Sumimasen Turbo',
    descript: 'SUMIMASEN TURBO, c’est déjà le rendez-vous incontournable de l’actualité du jeu vidéo japonais et de la pop culture. Tous les mois, trois vétérans et amis unis depuis plus de 25 ans partagent leur passion en ouvrant des parenthèses infinies. Du blockbuster AAA aux jeux de niche, rien n’échappe à leurs punchlines et leur enthousiasme.',
    img: 'https://assets.pippa.io/shows/63b943de1695af0011b9e885/1673085958014-080d53d1e36cd07fb791b9081e1accac.jpeg',
    img_sizes: {
      small: 'https://t.radiobrony.fr/3U05KUTf3ouW6HMnjP30eE8YAzQ=/150x0/https://assets.pippa.io/shows/63b943de1695af0011b9e885/1673085958014-080d53d1e36cd07fb791b9081e1accac.jpeg',
      medium: 'https://t.radiobrony.fr/CbF78OXcFJyFONAHgEL-LuasF2I=/300x0/https://assets.pippa.io/shows/63b943de1695af0011b9e885/1673085958014-080d53d1e36cd07fb791b9081e1accac.jpeg',
      large: 'https://t.radiobrony.fr/LjxH4_12Xd0K9sNq0pGvr2h_pW0=/450x0/https://assets.pippa.io/shows/63b943de1695af0011b9e885/1673085958014-080d53d1e36cd07fb791b9081e1accac.jpeg'
    },
    links: [
      {
        title: 'RSS',
        link: 'https://feeds.acast.com/public/shows/63b943de1695af0011b9e885',
        style: 'external'
      },
      {
        title: 'YouTube',
        link: 'https://www.youtube.com/@sumimasenturbo',
        style: 'youtube'
      },
      {
        title: '𝕏 (Twitter)',
        link: 'https://x.com/SumimasenTurbo',
        style: 'twitter-x'
      }
    ]
  },
  {
    title: 'Code Source',
    descript: 'Des histoires dans l’actualité racontées par les journalistes du Parisien, et par celles et ceux qui les ont vécues directement. Code Source est un podcast présenté par Jules Lavie, avec des reportages d’Ambre Rosala, du lundi au vendredi, à écouter dès 18 heures.',
    img: 'https://podcasts.podinstall.com/assets/img/rs/256/bdeb839c2ac998ab572175a5407bc8dfa030c051-f970609e.png',
    img_sizes: {
      small: 'https://t.radiobrony.fr/DyBu3y8Logoj2lOPkdNNk_syZl8=/150x0/https://podcasts.podinstall.com/assets/img/rs/256/bdeb839c2ac998ab572175a5407bc8dfa030c051-f970609e.png',
      medium: 'https://t.radiobrony.fr/U20hEwG_TkgA96vHbprWgFcA3Oo=/300x0/https://podcasts.podinstall.com/assets/img/rs/256/bdeb839c2ac998ab572175a5407bc8dfa030c051-f970609e.png',
      large: 'https://t.radiobrony.fr/GXpXAsU2eZUEk7s1XMpI_mYCoFI=/450x0/https://podcasts.podinstall.com/assets/img/rs/256/bdeb839c2ac998ab572175a5407bc8dfa030c051-f970609e.png'
    },
    links: [
      {
        title: 'Site',
        link: 'https://www.leparisien.fr/podcasts/code-source/',
        style: ''
      },
      {
        title: 'RSS',
        link: 'https://rss.acast.com/code-source',
        style: 'external'
      },
      {
        title: '𝕏 (Twitter)',
        link: 'https://x.com/codesource',
        style: 'twitter-x'
      }
    ]
  }
  /*
  {
    title: 'Yamanote',
    descript: 'Marie Palot nous emmène dans la Yamanote Line, la ligne de métro la plus connue de Tokyo. À l\'instar de cette loupe ferroviaire, nous ferons un 360 degrés autour de la culture manga animé.',
    img: 'https://cdn.radiofrance.fr/s3/cruiser-production/2021/12/4b462dd3-5a9b-4689-b2a3-3cd4459de7c9/1400x1400_rf_omm_0000032581_ite.jpg',
    img_sizes: {
      small: 'https://t.radiobrony.fr/crrFadhwWgSuhhX4K0IbAwDVxK4=/150x0/https://cdn.radiofrance.fr/s3/cruiser-production/2021/12/4b462dd3-5a9b-4689-b2a3-3cd4459de7c9/1400x1400_rf_omm_0000032581_ite.jpg',
      medium: 'https://t.radiobrony.fr/8gzZ4oYHVKX57Fpj884L2bE1gX4=/300x0/https://cdn.radiofrance.fr/s3/cruiser-production/2021/12/4b462dd3-5a9b-4689-b2a3-3cd4459de7c9/1400x1400_rf_omm_0000032581_ite.jpg',
      large: 'https://t.radiobrony.fr/KQ2uP0Ffu-4F9dgboZMdy1gp3sQ=/450x0/https://cdn.radiofrance.fr/s3/cruiser-production/2021/12/4b462dd3-5a9b-4689-b2a3-3cd4459de7c9/1400x1400_rf_omm_0000032581_ite.jpg'
    },
    links: [
      {
        title: 'Site',
        link: 'https://www.franceinter.fr/emissions/yamanote'
      },
      {
        title: 'RSS',
        link: 'https://radiofrance-podcast.net/podcast09/rss_22941.xml',
        style: 'external'
      },
      {
        title: 'App Radio France',
        link: 'https://www.radiofrance.com/application-mobile-radio-france',
        style: 'browsers'
      }
    ]
  },
  {
    title: 'Canapé Game',
    descript: 'C’est un podcast avec des gens sympas qui veulent parler de leur PASSION, à savoir le jeu vidéo et la culture pop japonaise.',
    img: 'https://uploads.podcloud.fr/uploads/covers/2e56/32e1/5fb9/39b7/3e9a/6913/5991/9416/0c92/870c/2e5632e15fb939b73e9a6913599194160c92870c.jpg',
    img_sizes: {
      small: 'https://t.radiobrony.fr/XiTJEMbAQrPx3f0Ib1KLFuEgW1s=/150x0/https://uploads.podcloud.fr/uploads/covers/2e56/32e1/5fb9/39b7/3e9a/6913/5991/9416/0c92/870c/2e5632e15fb939b73e9a6913599194160c92870c.jpg',
      medium: 'https://t.radiobrony.fr/u_AeAnK3PAS_GYUT6mPlxM_9X8M=/300x0/https://uploads.podcloud.fr/uploads/covers/2e56/32e1/5fb9/39b7/3e9a/6913/5991/9416/0c92/870c/2e5632e15fb939b73e9a6913599194160c92870c.jpg',
      large: 'https://t.radiobrony.fr/gCeDfh5Dy1085XdvOs8kae0qz0c=/450x0/https://uploads.podcloud.fr/uploads/covers/2e56/32e1/5fb9/39b7/3e9a/6913/5991/9416/0c92/870c/2e5632e15fb939b73e9a6913599194160c92870c.jpg'
    },
    links: [
      {
        title: 'Site',
        link: 'https://canape-game.lepodcast.fr/',
        style: ''
      },
      {
        title: 'RSS',
        link: 'https://canape-game.lepodcast.fr/rss',
        style: 'external'
      },
      {
        title: 'Twitch',
        link: 'https://www.twitch.tv/canapegame',
        style: 'twitch'
      },
      {
        title: '𝕏 (Twitter)',
        link: 'https://x.com/canape_game',
        style: 'twitter-x'
      }
    ]
  },
  {
    title: 'La Matinale Jeu Vidéo',
    descript: 'Du lundi au vendredi de 9h à 11h30 sur Twitch, Gautoz passe en revue les actus brûlantes du monde du jeu vidéo.',
    img: 'https://cloud.gautoz.cool/matinale-podcast/LaMatinaleJV.jpg',
    img_sizes: {
      small: 'https://t.radiobrony.fr/5wTogobfIh7vdmTvJL4Vmt5MKxs=/150x0/https://cloud.gautoz.cool/matinale-podcast/LaMatinaleJV.jpg',
      medium: 'https://t.radiobrony.fr/V3SMWBTXxu4BwvbT14QQPynPKLk=/300x0/https://cloud.gautoz.cool/matinale-podcast/LaMatinaleJV.jpg',
      large: 'https://t.radiobrony.fr/U2ufvgZYdR47tr1R9V3-Nfjx6r0=/450x0/https://cloud.gautoz.cool/matinale-podcast/LaMatinaleJV.jpg'
    },
    links: [
      {
        title: 'RSS',
        link: 'https://cloud.gautoz.cool/matinale-podcast/podcast_la-matinale-jv.xml',
        style: 'external'
      },
      {
        title: 'Twitch',
        link: 'https://www.twitch.tv/gautoz',
        style: 'twitch'
      },
      {
        title: 'YouTube',
        link: 'https://www.youtube.com/playlist?list=PLyh2wbBeYS9izB0S6JUiwq4cYdXcR_tjg',
        style: 'youtube'
      },
      {
        title: '𝕏 (Twitter)',
        link: 'https://x.com/gautoz',
        style: 'twitter-x'
      }
    ]
  },
  {
    title: 'J-Pop Social Club',
    descript: "Chaque mois, des gens un peu étranges se réunissent pour parler d'un univers musical bien obscur : la musique japonaise ou J-Music...",
    img: 'https://uploads.podcloud.fr/uploads/covers/51d2/e4b3/90c8/dd24/ea4a/c4a6/cb4c/538d/1808/a9a4/51d2e4b390c8dd24ea4ac4a6cb4c538d1808a9a4.jpg',
    img_sizes: {
      small: 'https://t.radiobrony.fr/Hf7F1bEC8KQjy9lP-NtaDqcMtlM=/150x0/https://uploads.podcloud.fr/uploads/covers/51d2/e4b3/90c8/dd24/ea4a/c4a6/cb4c/538d/1808/a9a4/51d2e4b390c8dd24ea4ac4a6cb4c538d1808a9a4.jpg',
      medium: 'https://t.radiobrony.fr/q9A6RN9hOjaMNqQQhpR7_YlNLow=/300x0/https://uploads.podcloud.fr/uploads/covers/51d2/e4b3/90c8/dd24/ea4a/c4a6/cb4c/538d/1808/a9a4/51d2e4b390c8dd24ea4ac4a6cb4c538d1808a9a4.jpg',
      large: 'https://t.radiobrony.fr/a6uBItcHINwM6VkHFDw7IDQ9jx4=/450x0/https://uploads.podcloud.fr/uploads/covers/51d2/e4b3/90c8/dd24/ea4a/c4a6/cb4c/538d/1808/a9a4/51d2e4b390c8dd24ea4ac4a6cb4c538d1808a9a4.jpg'
    },
    links: [
      {
        title: 'Site',
        link: 'https://jpopsocialclub.lepodcast.fr/',
        style: ''
      },
      {
        title: 'RSS',
        link: 'https://jpopsocialclub.lepodcast.fr/rss',
        style: 'external'
      },
      {
        title: '𝕏 (Twitter)',
        link: 'https://x.com/jpopsocialclub',
        style: 'twitter-x'
      },
      {
        title: 'Facebook',
        link: 'https://www.facebook.com/jpopsocialclub/',
        style: 'facebook'
      }
    ]
  }
  */
]
)
</script>

<style lang="scss">
.home-reco {
  .reco-fiche {
    align-items: center;
    background-color: #ffffff;
    border: 1px solid #808080;
    border-radius: 5px;
    display: flex;
    margin: 6px 3px;
    padding: 10px;
    width: calc(100% - 28px);

    .reco-image {
      display: none;
      min-width: 150px;
      width: 150px;

      img {
        align-self: center;
        height: auto;
        width: 100%;
      }
    }

    .reco-details {
      .reco-descript {
        border-left: 3px solid gray;
        margin: 10px 0;
        padding: 5px 10px;
      }

      .reco-links {
        .btn {
          line-height: 1.2; // Align stuff a bit
        }
      }
    }

    @media screen and (min-width: 720px) {
      .reco-image {
        display: block;
      }

      .reco-details {
        margin: 0 20px;
      }
    }
  }
}
</style>
