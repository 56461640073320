<template>
  <div class="home-timeline">
    <div v-if="error">
      <i>Oups, un problème. {{ error }}</i>
    </div>

    <div v-else-if="pending" class="loading">
      <span class="donut"></span><br/>
      Chargement des derniers posts...
    </div>

    <div v-else-if="posts && posts.length" class="posts">
      <div
        v-for="t in posts"
        :key="t.id"
        class="post">
        <div class="author">
          <img
            class="avatar"
            loading="lazy"
            :alt="t.author.name"
            :srcset="`${t.author.img_sizes.small}, ${t.author.img_sizes.medium} 2x, ${t.author.img_sizes.large} 3x`"
            :src="t.author.img_sizes.large"/>

          <div>
            <RepeatVariantIcon
              v-if="t.reblog"
              title="post repartagé"/>

            <b>
              <a
                :href="t.author.url"
                target="_blank" rel="noopener noreferrer">
                <span v-html="t.author.parsed_name" /></a>
            </b>

            <br/>

            <span class="handle">
              <a
                :href="t.author.url"
                target="_blank" rel="noopener noreferrer">
                @{{ t.author.handle }}</a>
            </span>
          </div>
        </div>

        <div class="message" v-html="t.message" />

        <div v-if="t.medias.length > 0" class="medias">
          <a
            v-for="m in t.medias"
            :key="m.id"
            :href="t.url"
            :style="`background-image: url(${m.proxy});`"
            :alt="m.description || 'Média attaché'"
            target="_blank"
            :title="m.description || 'Média attaché'"
            rel="noopener noreferrer">&nbsp;</a>
        </div>

        <div class="details">
          <span class="date">
            <a
              :href="t.url"
              target="_blank" rel="noopener noreferrer">
              {{ parseDateTime(t.timestamp) }}</a>
          </span>
        </div>
      </div>
    </div>

    <div v-else>
      <i>Oups, un imprévu est arrivé avec ce module.</i>
    </div>
  </div>
</template>

<script setup>
// Deps
import { DateTime } from 'luxon'
// Icons
import RepeatVariantIcon from '@mkody/vue-material-design-icons/RepeatVariant.vue'

const parseDateTime = (ts) => {
  const dt = DateTime.fromSeconds(ts)
  return dt.setLocale('fr').toLocaleString(DateTime.DATETIME_MED)
}

const { pending, data: posts, error } = useLazyFetch('https://node.radiobrony.fr/toots.json')
</script>

<style lang="scss">
.home-timeline {
  .loading {
    text-align: center;
  }

  .posts {
    display: flex;
    flex-wrap: wrap;

    .post {
      background-color: #ffffff;
      border: 1px solid #808080;
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      height: fit-content;
      margin: 3px;
      padding: 10px;
      width: 100%;

      .emoji {
        max-height: 1em;
        vertical-align: middle;
      }

      .author {
        display: flex;

        .avatar {
          border-radius: 5px;
          height: 48px;
          width: 48px;
        }

        div {
          padding: 5px 0 0 10px;

          a,
          a:visited {
            color: inherit;
            text-decoration: none;

            &:hover {
              text-decoration: underline;
            }
          }

          .handle {
            color: gray;
            font-size: 0.8em;

            &.repeat-variant-icon {
              color: #008000;
              font-size: 1em;
            }
          }
        }
      }

      .message {
        flex-grow: 1;
        // padding: 10px 0;

        a {
          color: inherit;
          line-break: anywhere;

          .invisible {
            display: none;
          }

          .ellipsis::after {
            content: '…';
          }
        }
      }

      .medias {
        align-content: center;
        display: flex;
        margin-bottom: 10px;
        width: 100%;

        a {
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          cursor: zoom-in;
          display: inline-block;
          flex-grow: 1;
          height: 100px;
          text-decoration: none;
        }
      }

      .details {
        display: flex;

        .actions {
          flex-grow: 1;
        }

        .date {
          font-size: 0.9em;
          text-align: right;
        }

        a,
        a:visited,
        .material-design-icon {
          color: gray;
          cursor: pointer;
          margin: 0 5px;
          text-decoration: none;

          &:hover {
            color: black;
          }
        }
      }
    }
  }

  @media (min-width: 720px) {
    .posts {
      .post {
        // 3 cols
        width: calc(100% / 3 - 28px);

        // Except the first two with 2 cols
        &:nth-child(1),
        &:nth-child(2) {
          width: calc(100% / 2 - 28px);
        }
      }
    }
  }
}
</style>
