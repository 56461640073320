<template>
  <div class="home-events">
    <!--
    <div class="event dailies">
      Ces programmes sont ajoutés automatiquement après le titre en cours.
      <ul>
        <li><b>Flash info :</b> 8h, 10h, 12h, 14h, 16h, 18h et 20h.</li>
        <li><b>Flash sports :</b> 12h.</li>
        <li><b>Météo du jour :</b> 8h et 10h.</li>
        <li><b>Météo de demain :</b> 18h et 20h.</li>
      </ul>
    </div>
    -->

    <div v-if="err || (eventsData && eventsData.error)">
      <i>Oups, un problème. {{ err || data.error }}</i>
    </div>

    <div v-else-if="pending" class="loading">
      <span class="donut"></span><br/>
      Chargement des événements...
    </div>

    <div
      v-for="ev in eventsData.events"
      v-else-if="eventsData && eventsData.events"
      :key="ev.id"
      :class="{ 'event': true, 'repeat': !ev.first }">
      <div v-if="ev.img && ev.img !== ''" class="event-card">
        <img
          loading="lazy"
          :srcset="`${ev.img_sizes.small}, ${ev.img_sizes.medium} 2x, ${ev.img_sizes.large} 3x`"
          :src="ev.img_sizes.large">
      </div>
      <div class="event-details">
        <div class="event-title">
          <b>{{ ev.title }}</b>
        </div>
        <div class="event-date">
          {{ getStartTime(ev.start) }}
        </div>
        <blockquote
          v-if="ev.description"
          class="event-description"
          v-html="ev.description"></blockquote>
      </div>
    </div>

    <div v-else>
      <i>Oups, un imprévu est arrivé avec ce module.</i>
    </div>

    <div class="the-end event">
      <div class="event-details">
        <div class="event-title">
          <b>{{ countdown }}</b>
        </div>
        <div class="event-date">
          {{ getStartTime(countdownTS) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, onBeforeMount } from 'vue'
import { DateTime } from 'luxon'

const sNow = ref(null)
const countdownTS = 1722463200
let tickInterval = null

const getStartTime = (ts) => {
  const dt = DateTime.fromMillis(ts * 1000).setLocale('fr')
  const day = dt.toLocaleString({ weekday: 'long', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' })
  return day.charAt(0).toUpperCase() + day.slice(1)
}

const { pending, data: eventsData, error: err } = useLazyFetch('https://node.radiobrony.fr/events.json')

const countdown = computed(() => {
  if (!sNow.value) return '...'

  const diff = countdownTS - sNow.value
  if (diff <= 0) return 'Fin des programmes sur Radio Brony'

  const days = Math.floor(diff / 86400)
  const hours = Math.floor((diff - days * 86400) / 3600)
  const minutes = Math.floor((diff - days * 86400 - hours * 3600) / 60)
  const seconds = Math.floor(diff - days * 86400 - hours * 3600 - minutes * 60)

  return `${days}j ${hours}h ${minutes}m ${seconds}s`
})

onMounted(() => {
  sNow.value = Date.now() / 1000

  tickInterval = setInterval(() => {
    sNow.value = Date.now() / 1000
  }, 1000)
})

onBeforeMount(() => {
  if (tickInterval) clearInterval(tickInterval)
})
</script>

<style lang="scss">
.home-events {
  .loading {
    text-align: center;
  }

  .event {
    align-items: center;
    background-color: #ffffff;
    border: 1px solid #808080;
    border-radius: 5px;
    display: flex;
    margin: 6px 3px;
    padding: 10px;
    width: calc(100% - 28px);

    &.dailies {
      border: 1px solid #8888ff;
      display: block;

      ul {
        margin: .4em 0 0 1.2em;
        padding: 0;
      }
    }

    &.repeat {
      display: none;
    }

    &.the-end {
      text-align: center;

      .event-title {
        font-size: 2rem;
      }
    }

    .event-card {
      display: none;
      min-width: 300px;
      width: 300px;

      img {
        align-self: center;
        height: auto;
        width: 100%;
      }
    }

    .event-details {
      flex-grow: 1;

      .event-date {
        margin-top: 4px;
      }

      .event-description {
        border-left: 3px solid gray;
        margin: 10px 0 0;
        padding: 5px 10px;
      }
    }

    @media screen and (min-width: 720px) {
      &.repeat {
        display: none; // use `block` to show multiple times the same show
      }

      &:not(.repeat) {
        .event-card {
          display: flex;
        }

        .event-details {
          margin: 0 20px;
        }
      }
    }
  }
}
</style>
